<template>
  <div>
    <div class="header">
      <h6>直播收入</h6>
      <div class="btn-wrap">
        <p class="btn"
           @click="exportClick"><i class="icon iconfont icon-daochu btn"></i><span>导出</span></p>
      </div>
    </div>
    <el-card style="margin-top:20px">
      <el-form :inline="true"
               :size="'small'"
               :model="formInline"
               class="demo-form-inline"
               ref="form">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="主播经纪人：">
              <brokerSelect @clearBrokerSelect="clearBrokerOneSelect"
                            @selectTreeChange="selectTreeOneChange"
                            :placeholder="'请选择主播经纪人'"></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收入人：">
              <brokerSelect @clearBrokerSelect="clearBrokerTwoSelect"
                            @selectTreeChange="selectTreeTwoChange"
                            :placeholder="'请选择收入人'"></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  v-role="['administrator']">
            <el-form-item label="归属人:">
              <belonger @belongerSeChange="id=>formInline.manager=id"></belonger>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="陌陌ID："
                          prop="tick_account">
              <el-input v-model="formInline.tick_account"
                        :placeholder="'请输入陌陌ID'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="直播日期："
                          prop="broadcast_date">
              <el-date-picker v-model="formInline.broadcast_date"
                              type="daterange"
                              :clearable="false"
                              range-separator="~"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-button type="primary"
                     @click="searchClick"
                     icon="el-icon-search">搜索</el-button>
        </el-row>
      </el-form>
      <tablePage :tableData="tableData"
                 style="margin-top:30px"
                 :currentPage="formInline.page"
                 :height="tableHeight"
                 @current-change="currentChange"
                 @sort-change="tableSortChange"
                 v-loading="loading"
                 ref="tableList"
                 :total="total"
                 id="table">
        <el-table-column label="主播信息"
                         min-width="300">
          <template slot-scope="scope">
            <div class="base-wrap">
              <colorfulAvatar :size="64"
                              :name="scope.row.anchor_name"></colorfulAvatar>
              <div class="info-wrap">
                <p>{{ scope.row.anchor_name }}</p>
                <p>陌陌ID：{{ scope.row.tick_account }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="昵称"
                         prop="nick_name"
                         min-width="200">
        </el-table-column>
        <el-table-column label="主播经纪人"
                         prop="anchor_broker_name"
                         min-width="200">
        </el-table-column>
        <el-table-column label="收入人"
                         prop="income_broker_name"
                         min-width="200">
        </el-table-column>
        <el-table-column label="主播礼物收入"
                         prop="broadcast"
                         min-width="200">
        </el-table-column>
        <el-table-column label="收入(元)"
                         :sortable="'custom'"
                         prop="amount"
                         min-width="200">
        </el-table-column>
        <el-table-column label="直播日期"
                         sortable="custom"
                         prop="broad_cast_date"
                         min-width="200">
        </el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>

<script>
import brokerSelect from 'components/brokerSelect'
import tablePage from 'components/tablePage'
import api from '@/api/index'
import tableHeight from 'mixins/tableHeight'
import colorfulAvatar from 'components/colorfulAvatar'
import authRouter from 'mixins/authRouter'
import belonger from 'components/belonger'
export default {
  components: {
    brokerSelect,
    tablePage,
    colorfulAvatar,
    belonger
  },
  mixins: [tableHeight, authRouter],
  data () {
    return {
      formInline: {
        tick_account: '',
        broadcast_date: [],
        broker_id: '',
        distributor: '',
        page: 1,
        order: ''
      },
      tableData: [],
      loading: true,
      total: 10
    }
  },
  created () {
    this.dateFn()
    this._initState()
  },
  methods: {
    tableSortChange ({ prop, order }) {
      if (order) {
        this.formInline.order = `${prop},${order === 'descending' ? order.substr(0, 4) : order.substr(0, 3)}`
        this._initState()
      }
    },
    dateFn () {
      this.formInline.broadcast_date.push(this.$timeFormat(
        new Date().setTime(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ),
        "yyyy-mm-dd"
      ))
      this.formInline.broadcast_date.push(this.$timeFormat(new Date().getTime(), "yyyy-mm-dd"))
    },
    async _initState (params = this.formInline) {
      const data = await api.incomeListApi(params)
      if (data.status_code === 200) {
        this.tableData = data.message
        this.total = data.meta.pagination.total
        this.loading = false
      } else if (data.status_code === 422) {
        this.$message({
          message: data.message.broadcast_date,
          type: 'error'
        });
      }
    },
    searchClick () {
      this.formInline.page = 1
      this._initState()
    },
    selectTreeTwoChange (id) {
      this.formInline.distributor = id
    },
    clearBrokerTwoSelect () {
      this.formInline.distributor = ''
    },
    clearBrokerOneSelect () {
      this.formInline.broker_id = ''
    },
    selectTreeOneChange (id) {
      this.formInline.broker_id = id
    },
    exportClick () {
      this.$excelDownload(this.formInline, "api/income/export");
    },
    currentChange (page) {
      this.formInline.page = page
      this._initState()
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
h6 {
  font-size: 20px;
  color: #333333;
  font-weight: 500;
}
.btn {
  line-height: 20px;
  font-size: 16px;
  color: #3278eb;
  cursor: pointer;
  span {
    display: inline-block;
    margin-left: 4px;
  }
}
.base-wrap {
  display: flex;
  div {
    margin-left: 10px;
  }
  p {
    font-size: 14px;
    color: #333333;
  }
}
.info-wrap {
  margin-top: 10px;
  margin-left: 10px;
}
</style>